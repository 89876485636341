import React, { useContext, useEffect, useState } from "react";


import "./BannerPart3.css";
import axios from "axios";
import { baseUrl, dataContext, portal } from "../../App";
import { useNavigate } from "react-router-dom";
import activityLogSubmission from "../../helper/activitylog";
import RotatingBox from "./RotatingBox";
import analyticEvent from "../../helper/gaEvent";
import SliderBanner from "./SliderBanner";
import logoImage from './img/logo.png'
import playBtnImage from './img/settings.png'
import banglaImage from './img/bangla.png'
import englishImage from './img/english.png'
const BannerPart3 = ({ props }) => {
  const navigate = useNavigate();
  const { token, setToken, playerData, setPlayerData, events, setEvents } =
    useContext(dataContext);
  const { showLoginModal, setShowLoginModal,showLoadingModal, setShowLoadingModal,showRedeemModal,setShowRedeemModal } = props;

  async function quizClickHandler(eventId) {
  console.log(playerData)
    if (!(playerData?.id)) {
      setShowLoginModal(true);
      sessionStorage.setItem("login_event_id", eventId); 
    }else{
      if (playerData?.isSubscribe) {
        navigate(`/quiz/?eventId=${eventId}`, { replace: true });
      } else {
        // if (playerData?.redeemed_code) {
          setShowLoadingModal(true)
          const config = {
            headers: { Authorization: `Token ${token}` },
          };
          axios
            .post(
              `${baseUrl}/wordly/subscription/create/`,
              { portal: portal, qcid: sessionStorage.getItem("cid") || null, "service_type": "Wordly" },
              config
            )
            .then((response) => {
              let data = response.data;
              setShowLoadingModal(false)
              if (data?.redirectURL) {
                console.log(`${data?.redirectURL}`);
                window.location.replace(`${data?.redirectURL}`);
              }
            });
        } 
        // else {
          // setShowRedeemModal(true)
        // }
      }
  }
  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("home", msisdn);
    analyticEvent('home', 'home-view')
  }, []);
  let userScore = [];
  userScore = events.map((event) => {
    let userLeaderboard = [];
    if (playerData?.id) {
      userLeaderboard = playerData?.leaderboard?.filter((singleEvent) => {
        if (singleEvent.event_id == event.id) {
          return singleEvent;
        }
      });
      return {
        eventTitle: event.events,
        score: userLeaderboard[0]?.score,
      };
    } else {
      return {
        eventTitle: event.events,
        score: "-",
      };
    }
  });
  // console.log(userScore);
  let userJsx = ``;
  for (let i = 0; i < userScore.length; i++) {
    if (i == 0) {
      userJsx = `<div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    } else {
      userJsx += `
    <div className="horizontalBar"></div>
    <div className="singleEventScore">
      <div>${userScore.eventTitle}</div>
      <div>${userScore.score}</div>
    </div>`;
    }
  }
  return (
    <>
      {/* <div className="header-img-div"> */}
        {/* <img src={playerCard} className="header-img" alt="" srcSet="" /> */}
        {/* <div className="bannerAvatar-div">
          <img
            src={
              playerData?.avatar_img
                ? `${baseUrl}${playerData?.avatar_img}`
                : require(`../../assets/avatar/avatar${playerData?.avatar_id ? playerData.avatar_id : 1
                  }.png`)
            }
            className="banner-avatarImg"
            alt=""
            srcSet=""
          />
        </div> */}

        {/* <div className="userEventScore">
          {userScore.map((singleUserScore, index) => {
            if (index == 0) {
              return (
                <div className="singleEventScore" key={index}>
                  <div>{singleUserScore.eventTitle}</div>
                  <div>{singleUserScore.score}</div>
                </div>
              );
            } else {
              return (
                <React.Fragment key={index}>
                  <div className="horizontalBar"></div>
                  <div className="singleEventScore">
                    <div>{singleUserScore.eventTitle}</div>
                    <div>{singleUserScore.score}</div>
                  </div>
                </React.Fragment>
              );
            }
          })}
        </div> */}
      {/* </div> */}
      <div className="bannerDiv">
        {/* <SliderBanner props={{ events,quizClickHandler }} /> */}
        <img src={logoImage} alt="" className="mixupLogoImg"  />
        <div className="mixupPlayBtnImg">
          <img src={playBtnImage} alt="" style={{width:'100%'}} />
          <img src={englishImage} alt="" className="mixupEnglishImg" onClick={()=>{quizClickHandler(events[0]?.id)}} />
          <img src={banglaImage} alt="" className="mixupBanglaImg" onClick={()=>{quizClickHandler(events[1]?.id)}} />
        </div>
      {/* <div className="mixupText">
      </div> */}
      {/* <div className="mixupPlayBtn" onClick={()=>{quizClickHandler(62)}}>PLAY</div> */}
      </div>
    </>
  );
};

export default BannerPart3;
