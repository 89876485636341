import React, { useContext, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import './ContactModal.css'
import '../../../assets/css/modal.css'
import { dataContext } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import styles from './ContactModal.module.css';
const ContactModal = ({ showContactModal, setShowContactModal, setShowRefundModal }) => {
  const { token, setToken, playerData, setPlayerData } = useContext(dataContext);

  function hideModalOperation() {
    setShowContactModal(false)
  }

  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("contacts", msisdn);
    analyticEvent('contact', 'contact-view')
  }, [])


  return (
    <>
      <Modal show={showContactModal} centered dialogClassName="modal-dialog" contentClassName="modalContentHelp" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>হেল্প</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "65vh", overflow: "scroll", display: 'block', overflowX: 'hidden' }}>
          <div className={styles['faq-container']}>
            <div className={styles['faq-content']}>
              <div className={styles['faq-item']}>
                <p className={styles['question']}>১। হেল্পলাইন নম্বরটি কি?</p>
                <p className={styles['answer']}>উঃ <a href="tel:+8801988117755">8801988117755</a></p>
              </div>

              <div className={styles['faq-item']}>
                <p className={styles['question']}>২। হেল্পলাইন ফোন করার সময়সূচী?</p>
                <p className={styles['answer']}>উঃ রবিবার থেকে বৃহস্পতিবার সকাল ১০টা থেকে বিকাল ৬ টা পর্যন্ত।</p>
              </div>

              <div className={styles['faq-item']}>
                <p className={styles['question']}>৩। সাপোর্ট ইমেইল আইডি কি?</p>
                <p className={styles['answer']}>উঃ <a href="mailto:wordly.live@yahoo.com">wordly.live@yahoo.com</a></p>
              </div>

              <div className={styles['faq-item']}>
                <p className={styles['question']}>৪। Wordly লিংকটি কি?</p>
                <p className={styles['answer']}>উঃ <a href="https://wordly.quizard.live/" target="_blank" rel="noopener noreferrer">https://wordly.quizard.live/</a></p>
              </div>

              <div className={styles['faq-item']}>
                <p className={styles['question']}>৫। Wordly এর ফেসবুক লিংকটি কি?</p>
                <p className={styles['answer']}>উঃ <a href="https://www.facebook.com/wordly.quizard.live/" target="_blank" rel="noopener noreferrer">fb.com/wordly.quizard.live</a></p>
              </div>

              <div className={styles['faq-item']}>
                <p className={styles['question']}>৬। আমাদের সাথে সরাসরি যোগাযোগ করতে পারেন এভাবেও -</p>
                <p className={styles['answer']}>
                  <a href="https://www.facebook.com/wordly.quizard.live/" target="_blank">
                    <i className="fa-brands fa-facebook"></i>
                  </a>
                  <a href="https://www.messenger.com/t/wordly.quizard.live" target="_blank">
                    <i className="fa-brands fa-facebook-messenger"></i>
                  </a>
                  <a href="whatsapp://send?phone=+8801988117755" target="_blank">
                    <i className="fa-brands fa-whatsapp"></i>
                  </a>
                  <a href="https://www.instagram.com/wordly.puzzle/" target="_blank">
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </p>
              </div>
              <div className={styles['faq-item']}>
                <p className={styles['question']}>৭| Wordly থেকে আন্সাবস্ক্রাইব করতে নিচের বাটন ক্লিক করতে হবে-  </p>
                <p className={styles['answer']}>
                  {((playerData?.isSubscribe) || (playerData?.isSubscribeTournament)) && (
                    <div className={styles['unsubscribe-button-container']}>
                      <button
                        className={styles['unsubscribe-btn']}
                        onClick={() => {
                          setShowRefundModal(true);
                          setShowContactModal(false)
                        }}
                      >
                        <span>Unsubscribe</span>
                      </button>
                    </div>
                  )}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ContactModal