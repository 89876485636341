// Function to split a word into characters while keeping modifiers attached
export function splitWordPreservingModifiers(word) {
  const hasBengali = /[\u0980-\u09FF]/.test(word);
  if (!hasBengali) {
    return word.toString().toUpperCase().split('');
  }
  let chars = Array.from(word);
  let result = [];
  let current = '';

  for (let i = 0; i < chars.length; i++) {
    let char = chars[i];

    // Handle য় and ঢ় cases with all modifiers
    if ((char === 'ড' || char === 'ঢ') && chars[i + 1] === '়') {
      if (current) {
        result.push(current);
        current = '';
      }
      let cluster = char + '়';
      i++; // Skip ়

      // Include any following vowel marks AND অনুস্বর/বিসর্গ
      while (i + 1 < chars.length && /[া-ৗঃংঁ]/.test(chars[i + 1])) {
        cluster += chars[i + 1];
        i++;
      }
      result.push(cluster);
      continue;
    }

    // Handle য়্য case (like in সফটওয়্যার)
    if (char === 'য' && chars[i + 1] === '়' && chars[i + 2] === '্') {
      if (current) {
        result.push(current);
        current = '';
      }
      let cluster = 'য়্য';
      i += 3; // Skip ়, ্, and য

      // Include any following vowel marks
      while (i + 1 < chars.length && /[া-ৗ]/.test(chars[i + 1])) {
        cluster += chars[i + 1];
        i++;
      }
      result.push(cluster);
      continue;
    }

    // Handle multiple conjuncts (like ন্ত্র)
    if (chars[i + 1] === '্') {
      if (current) {
        result.push(current);
        current = '';
      }

      let conjunct = char;
      let hasMore = true;

      while (hasMore && i + 1 < chars.length) {
        if (chars[i + 1] === '্') {
          conjunct += '্' + chars[i + 2];
          i += 2;
        } else {
          hasMore = false;
        }
      }

      // Add any following vowel marks and অনুস্বর/বিসর্গ
      while (i + 1 < chars.length && /[া-ৗঃংঁ]/.test(chars[i + 1])) {
        conjunct += chars[i + 1];
        i++;
      }

      result.push(conjunct);
      continue;
    }

    // Handle য় case
    if (char === 'য' && chars[i + 1] === '়') {
      if (current) {
        result.push(current);
        current = '';
      }
      let য়_cluster = 'য়';
      i++; // Skip ়

      // Include any following vowel marks and অনুস্বর/বিসর্গ
      while (i + 1 < chars.length && /[া-ৗঃংঁ]/.test(chars[i + 1])) {
        য়_cluster += chars[i + 1];
        i++;
      }
      result.push(য়_cluster);
      continue;
    }

    // Handle vowel marks and other modifiers
    if (/[া-ৗঃংঁ]/.test(char)) {
      current += char;
    } else {
      if (current) {
        result.push(current);
      }
      current = char;
    }
  }

  // Push any remaining character
  if (current) {
    result.push(current);
  }

  return result;
}
