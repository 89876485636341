/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import '../../../assets/css/modal.css'
import './RefundModal.css'
import { baseUrl, dataContext, portal } from '../../../App';
import activityLogSubmission from '../../../helper/activitylog';
import analyticEvent from '../../../helper/gaEvent';
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

const RefundModal = ({ showRefundModal, setShowRefundModal, setToggleToUpdate, toggleToUpdate, setShowUnSubscriptionModal }) => {
  const { token, setToken, playerData, setPlayerData, events, setEvents } = useContext(dataContext);
  const [selectedRefund, setSelectedRefund] = useState('unsubscribe')
  const [showError, setShowError] = useState(false)
  const [reason, setReason] = useState('unsubscribe')
  const [mainReason, setMainReason] = useState('rulesNotClear');
  const [input, setInput] = useState('');
  const [errorOutLine, setErrorOutLine] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  function hideModalOperation() {
    setShowRefundModal(false)
  }
  function submitOperation() {
    if (input) {
      setIsLoading(true)
      axios
        .get(`${baseUrl}/wordly/subscription/cancel/?portal=${portal}&reason=${mainReason}_${input}&serviceType=Wordly`, {
          headers: {
            Authorization: `Token ${token}`,
          }
        })
        .then((response) => {
          setIsLoading(false)
          console.log(response)
          setShowError(false)
          setToggleToUpdate(!toggleToUpdate)
          setShowRefundModal(false)
          setShowUnSubscriptionModal(true)
        }).catch((res)=>{
          console.log(res)
          setShowError(true)
          setToggleToUpdate(!toggleToUpdate)
          setShowUnSubscriptionModal(false)
        });
    } else {
      setErrorOutLine(true)
    }
  }

  const onOptionChange = e => {
    setSelectedRefund(e.target.value)
  }
  useEffect(() => {
    let msisdn = null;

    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission("refund", msisdn);
    analyticEvent('refundUnsubscribe', 'refundUnsubscribe-view')
  }, [])
  return (
    <>
      <Modal show={showRefundModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>আনসাবস্ক্রাইব</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <img src={playerData?.avatar_img
              ? `${baseUrl}${playerData?.avatar_img}`
              : require(`../../../assets/avatar/avatar${playerData?.avatar_id ? playerData.avatar_id : 1
                }.png`)} className="refundProfileImg" alt="" srcSet="" />
            <div className="detailBox text-center">
              <div className="detailName">{((playerData?.player_name) ? playerData.player_name : 'ইউজারনেম')}</div>
              <div className="refundPlayerMobile">{((playerData?.id) ? `0${(playerData.msisdn).toString().slice(-10)}` : '-')}</div>
            </div>
            <div className="radioBox mt-2">
              {/* <div className="refundBox">
                <input type="radio" id="refund1" name="refund" value="refund" checked={selectedRefund === 'refund'} onChange={onOptionChange} />
                <label htmlFor="refund1" className='mx-2'>Refund</label>
              </div> */}
              <div className="unsubscribeBox">
                <input type="radio" id="refund2" name="refund" value="unsubscribe" checked={selectedRefund === 'unsubscribe'} onChange={onOptionChange} />
                <label htmlFor="refund2" className='mx-2'>Unsubscribe</label><br />
              </div>
            </div>
            <div className="mt-3">
              <label htmlFor="reasons">কারণ:</label>
              {/* {(selectedRefund === 'refund') && <select name="reasons" id="reasons" className="refundSelect">
                <option value="r2">সার্ভিসে সমস্যা</option>
                <option value="r3">কুইজের প্রশ্নে সমস্যা</option>
                <option value="r4">প্রাইজ পাইনি</option>
              </select>} */}
              {(selectedRefund === 'unsubscribe') && <select name="reasons" id="reasons" className="refundSelect" value={mainReason} onChange={(e) => { setMainReason(e.target.value) }}>
                <option value="rulesNotClear">নিয়ম বুঝিনি</option>
                <option value="questionHard">প্রশ্ন অনেক কঠিন</option>
                <option value="didnotGetPrize">প্রাইজ পাইনি</option>
              </select>}
            </div>
            <div style={{ width: '80%' }} className="mt-2">
              <label htmlFor="detailsReason">বিস্তারিত*:</label>
              <textarea name="detailsReason" id="detailsReason" rows="5"
                className={`refundTextArea ${(errorOutLine) ? 'requiredErrorOutline' : ''}`} value={input} onInput={e => { setInput(e.target.value); setErrorOutLine(false) }}></textarea>
            </div>
            {showError && <div style={{ color: "#ff0000", textAlign: 'center', fontWeight: 600 }}>*Unsubscription Failed</div>}
          </>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn numberSubmit" onClick={submitOperation}>
            {isLoading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Submit"
            )}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RefundModal