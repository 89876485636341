import React from "react";
import { baseUrl } from "../../App";
import crown from "../../assets/img/v4/crown.png";

const HallofFame = ({ rankOneData, rankTwoData, rankThreeData }) => {
  return (<>
    <div className="hallOfFame" style={{ color: "white", fontSize: "2vh" }}>
      <div className="insideBorder"></div>
      <div
        className={`rank2 ${rankTwoData?.User_Rank ? "" : "inactive"}`}
        style={{
          width: "30%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          color:'#f5f5f5',
          fontWeight:'600'
        }}
      >
        <div
          className=""
          style={{
            width: "90%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div className="">2nd&nbsp;</div>
          <img
            className="rankTwoImg"
            src={
              rankTwoData?.avatar_img
                ? `${baseUrl}/media/${rankTwoData?.avatar_img}`
                : require(`../../assets/avatar/avatar${rankTwoData?.avatar_id ? rankTwoData.avatar_id : 1
                  }.png`)
            }
            alt=""
            srcSet=""
          />
          <div className="">
            0
            {rankTwoData?.msisdn
              ? (rankTwoData?.msisdn).toString().slice(0, 3)
              : ""}
            ****
            {rankTwoData?.msisdn
              ? (rankTwoData?.msisdn).toString().slice(-3)
              : ""}
            &nbsp;
          </div>
          <div className="hallOfFame_score">Score: {rankTwoData?.score || "-"} &nbsp;</div>
        </div>
      </div>
      <div
        className={`rank1 ${rankOneData?.User_Rank ? "" : "inactive"}`}
        style={{
          width: "37%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          color:'#f5f5f5',
          fontWeight:'600'
        }}
      >
        <div
          className=""
          style={{
            width: "92%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "end",
            position: "relative",
          }}
        >
          <img src={crown} className="crownImg" alt="" srcSet="" />
          <img
            src={
              rankOneData?.avatar_img
                ? `${baseUrl}/media/${rankOneData?.avatar_img}`
                : require(`../../assets/avatar/avatar${rankOneData?.avatar_id ? rankOneData.avatar_id : 1
                  }.png`)
            }
            className="rankOneImg"
            alt=""
            srcSet=""
          />
          <div className="">
            0
            {rankOneData?.msisdn
              ? (rankOneData?.msisdn).toString().slice(0, 3)
              : ""}
            ****
            {rankOneData?.msisdn
              ? (rankOneData?.msisdn).toString().slice(-3)
              : ""}
            &nbsp;
          </div>
          <div className="hallOfFame_score">Score: {rankOneData?.score || "-"} &nbsp;</div>
        </div>
      </div>
      <div
        className={`rank3 ${rankThreeData?.User_Rank ? "" : "inactive"}`}
        style={{
          width: "30%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "end",
          color:'#f5f5f5',
          fontWeight:'600'
        }}
      >
        <div
          className=""
          style={{
            width: "90%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <div className="">&nbsp;3rd</div>
          <img
            src={
              rankThreeData?.avatar_img
                ? `${baseUrl}/media/${rankThreeData?.avatar_img}`
                : require(`../../assets/avatar/avatar${rankThreeData?.avatar_id ? rankThreeData?.avatar_id : 1
                  }.png`)
            }
            className="rankThreeImg"
            alt=""
            srcSet=""
          />
          <div className="">
            0
            {rankThreeData?.msisdn
              ? (rankThreeData?.msisdn).toString().slice(0, 3)
              : ""}
            ****
            {rankThreeData?.msisdn
              ? (rankThreeData?.msisdn).toString().slice(-3)
              : ""}
            &nbsp;
          </div>
          <div className="hallOfFame_score">
            Score: {rankThreeData?.score || "-"} &nbsp;
          </div>
        </div>
      </div>
    </div>
  </>)
}

const RankTable = ({ leaderBoardData }) => {
  return (<div className="rankTable mt-1" style={{ color: "white" }}>
    {leaderBoardData.map((singleData, index) => {
      return (
        <React.Fragment key={index}>
          <div className="perRow mt-3 mx-1" key={singleData.User_Rank}>
            <div className="tdRank">{singleData.User_Rank}</div>
            <div className="tdSmallAvatar">
              <img
                src={
                  singleData?.avatar_img
                    ? `${baseUrl}/media/${singleData?.avatar_img}`
                    : require(`../../assets/avatar/avatar${singleData.avatar_id ? singleData.avatar_id : 1}.png`)
                }
                className="tdSmallAvatarImg"
                alt=""
                srcSet=""
              />
            </div>
            <div className="tdMobileNumber">
              0{(singleData?.msisdn).toString().slice(0, 3)}****
              {(singleData?.msisdn).toString().slice(-3)}
            </div>
            <div className="tdTime">{singleData.time_taken}s</div>
            <div className="tdScore">{singleData.score}</div>
          </div>
        </React.Fragment>
      );
    })}
  </div>)
}


const UserRow = ({ playerData, activeBoard }) => {
  let userLeaderBoard = {};
  if (playerData?.id) {
    userLeaderBoard = {};
    let data = playerData.leaderboard.filter((value) => {
      if (value.event_id === activeBoard) return value;
    });
    userLeaderBoard = data[0];
  } else {
    userLeaderBoard = {};
  }
  return (<>
    <div className="userRow" style={{ color: "white" }}>
      {playerData?.id ? (
        <>
          <div className="perRow mt-2 mx-1">
            <div className="tdRank">
              {userLeaderBoard?.round_number
                ? `${userLeaderBoard.User_Rank}`
                : "-"}
            </div>
            <div className="tdSmallAvatar">
              <img
                src={
                  playerData?.avatar_img
                    ? `${baseUrl}${playerData?.avatar_img}`
                    : require(`../../assets/avatar/avatar${playerData?.avatar_id ? playerData.avatar_id : 1
                      }.png`)
                }
                className="tdSmallAvatarImg"
                alt=""
                srcSet=""
              />
            </div>
            <div className="tdMobileNumber">
            আপনার স্কোর:
            </div>
            <div className="tdTime">
              {(playerData?.id) ? userLeaderBoard?.time_taken : "0"}s
            </div>
            <div className="tdScore"> {`${(userLeaderBoard?.score != undefined) ? userLeaderBoard?.score : '-'}`}</div>
          </div>
        </>
      ) : (
        <div className="perRow mt-2 mx-1">
          <div className="tdRank">-</div>
          <div className="tdSmallAvatar">
            <img
              src={require(`../../assets/avatar/avatar1.png`)}
              className="tdSmallAvatarImg"
              alt=""
              srcSet=""
            />
          </div>
          <div className="tdMobileNumber">***</div>
          <div className="tdTime">0s</div>
          <div className="tdScore"> - </div>
        </div>
      )}
    </div>
  </>)
}


export { HallofFame, RankTable, UserRow };

