import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './QuestionBodyBoishakh.module.css';
import timerAudio from '../../../assets/sound/timer.mp3'
import axios from 'axios'
import { baseUrl, dataContext, portal } from '../../../App'
import { useElapsedTime } from 'use-elapsed-time'
import { colorCleanUp, secondsToMS, translateToBanglaNum } from './questionHelper'
import TimerBoxLinear from '../TimerBoxLinear/TimerBoxLinear'
import CircularTimer from '../CircularTimer/CircularTimer'
import ProgressBar from '../ProgressBar/ProgressBar'
import activityLogSubmission from '../../../helper/activitylog'
import MixUp from './MixUp';


const QuestionBodyBoishakh = ({ eventDetails, finalScore, setFinalScore, setShowResultModal, playerRound }) => {
  const { token, setToken, playerData, setPlayerData, events, setEvents } = useContext(dataContext);
  // const [timerTick, setTimerTick] = useState(new Audio(timerAudio)) 
  const [allQuestions, setAllQuestions] = useState([])
  const [currentQues, setCurrentQues] = useState({})
  const [currentQuesIndex, setCurrentQuesIndex] = useState(null)
  const [isLoadingEnd, setIsLoadingEnd] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  // const [totalRightAnswer, setTotalRightAnswer] = useState(0)
  const [totalWrongAnswer, setTotalWrongAnswer] = useState(0)
  const [timerWidth, setTimerWidth] = useState(100)
  const [progressWidth, setProgressWidth] = useState(0)
  const [singleTimerWidth, setsingleTimerWidth] = useState(100)
  const [timerTime, setTimerTime] = useState(eventDetails.allocated_time)
  const [singleTime, setSingleTime] = useState(6)
  const [isPlaying, setIsPlaying] = useState(false);
  const { elapsedTime } = useElapsedTime({ isPlaying });


  useEffect(() => {
    axios.get(`${baseUrl}/api/questions/?rules=${eventDetails.id}&portal=${portal}`, {
      headers: { Authorization: `Token ${token}` }
    })
      .then(response => {
        setAllQuestions(response.data)
        console.log(response.data)
        setIsLoadingEnd(true)
        setCurrentQuesIndex(0)
        // let totalTime = (response.data).reduce(function (accumulator, currentValue) {
        //   // console.log(currentValue)
        //   return accumulator + (currentValue?.time_allocated ? currentValue.time_allocated : 6);
        // }, 0);
        // console.log(totalTime)
        // setTimerTime(totalTime)
        // insertData(0, playerData.msisdn, playerRound + 1, 1, 'initial', 'general', eventDetails.id)
        // tickingElm.current = new Audio(timerAudio)
        // tickingElm.current.play()
        // tickingElm.current.loop = true;

        // timerTick.load()
        // timerTick.play()
        // timerTick.loop = true
      })
  }, [])

  // useEffect(() => {
  //   setFinalScore(totalRightAnswer)
  // }, [totalRightAnswer])

  // timerCode
  // let timerTime = eventDetails.allocated_time;
  let remainingWidth = (((timerTime - elapsedTime) / timerTime) * 100).toFixed(2);
  let remainingTime = (((timerTime - elapsedTime))).toFixed(2);

  useEffect(() => {
    setTimerWidth(remainingWidth)
  }, [remainingWidth])

  if (elapsedTime > timerTime) {
    if (isPlaying) {
      setIsPlaying(false)
      gameDone()
    }
  }



  useEffect(() => {
    let msisdn = null;
    if (playerData?.id) {
      msisdn = playerData?.msisdn;
    }
    activityLogSubmission(`events-${eventDetails.id}`, msisdn);
    return () => {
      // cleanup function
      console.log("in cleanup")
    }
  }, [])

  // Loading: [Done]
  useEffect(() => {
    if (isLoadingEnd) {
      console.log(currentQues)
      setIsPlaying(true)
    }
  }, [currentQuesIndex])


  // console.log(allQuestions)
  // function handleAnswerClick(event, answerId) {
  //   if (isSubmitted) return;
  //   setIsSubmitted(true)

  //   let isRight = 0;
  //   let mobile = playerData.msisdn;
  //   let round = playerRound + 1;
  //   let questionId = currentQues.id;
  //   let selectedAns = `Option${answerId}`;
  //   let category = `general`;

  //   if (parseInt((currentQues.ans).slice(-1)) === answerId) {
  //     event.currentTarget.classList.add('bg_right')
  //     new Audio(rightAudio).play();
  //     setTotalRightAnswer((totalRightAnswer + 1))
  //     isRight = 1;
  //   } else {
  //     event.currentTarget.classList.add('bg_wrong')
  //     new Audio(wrongAudio).play()
  //     setTotalWrongAnswer((totalWrongAnswer + 1))
  //   }
  //   insertData(isRight, mobile, round, questionId, selectedAns, category, eventDetails.id);
  //   if (currentQuesIndex < (allQuestions.length - 1)) {
  //     setTimeout(() => {
  //       setIsPlaying(false)
  //       colorCleanUp('singleOptions')
  //       setCurrentQuesIndex((currentQuesIndex + 1))
  //       setIsSubmitted(false)
  //     }, 400);
  //   } else {
  //     gameDone()
  //   }
  // }

  function gameDone() {
    setIsPlaying(false)
    setShowResultModal(true)
  }

  function singleTimerSubmit() {
    // console.log('singleSubmitted')
    insertData(0, playerData.msisdn, playerRound + 1, currentQues.id, 'notSelected', 'general', eventDetails.id)
    setIsSubmitted(false)
    setTotalWrongAnswer((totalWrongAnswer + 1))
    if (currentQuesIndex < (allQuestions.length - 1)) {
      setCurrentQuesIndex((currentQuesIndex + 1))
    } else {
      gameDone()
    }
  }

  function insertData(isRight, mobile, round, questionId, selectedAns, category, eventId) {
    // insert Data Function
    // console.log(isRight, mobile, round, questionId, selectedAns, category, eventId)
    axios.post(`${baseUrl}/api/participant/`, {
      is_right: isRight,
      msisdn: mobile,
      round_number: round,
      question: questionId,
      selected_answer: selectedAns,
      category: category,
      event: eventId
    }, {
      headers: { Authorization: `Token ${token}` }
    });
  }
  return (
    <>
      {allQuestions?.length && <MixUp props={{ insertData, playerData, playerRound, eventDetails, setFinalScore, setShowResultModal, gameDone,allQuestions,remainingTime,timerWidth }} />}
      {/* insertData(0, playerData.msisdn, playerRound + 1, currentQues.id, 'notSelected', 'general', eventDetails.id) */}
    </>
  )
}

export default QuestionBodyBoishakh