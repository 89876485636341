import gsap from 'gsap'
import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import './MixUp.css'
import { Draggable } from 'gsap/all';
import { words_filter } from './words';
import TimerBoxLinear from '../TimerBoxLinear/TimerBoxLinear';
import { IoArrowUndoOutline } from "react-icons/io5";
import { secondsToMS } from './questionHelper';
import { splitWordPreservingModifiers } from './helperFunc';
const MixUp = ({ props }) => {
  const [totalRightAnswer, setTotalRightAnswer] = useState(0)
  const { insertData, playerData, playerRound, eventDetails, setFinalScore, setShowResultModal, gameDone, allQuestions, remainingTime, timerWidth } = props;
  var wordArr, mixedArr, // arrays that hold the original and jumbled letters
    timeLimit = 30 * 1000, // in millisec
    timeLeft,
    timeInt,
    currentTile, // updated when you pickup a tile
    emptySlot; // holds the X position of the empty slot
  const currentQuestionIndex = useRef(0);
  var i = 1;
  // ************** Customization ****************************
  timeLimit = (eventDetails.allocated_time || 0) * 1000;
  let questionsIdLocal = 2107;
  let noOfLetter = 5;
  let timePlusSec = 1 * 500;

  var tileFrontImage = 'url(/front.png)'
  var tileShadeImage = 'url(https://assets.codepen.io/721952/tileShade.png)'

  var successBackground = 'rgba(60,250,60,0.8)'
  var letterColor = '#430676'

  useEffect(() => {
    setFinalScore(totalRightAnswer)
  }, [totalRightAnswer])


  var cellSize = 50;
  var cellDistance = cellSize + 6;
  var divWidth = $('.slotAndTiles').width();
  var leftSide = ((divWidth - ((cellDistance * 4) + cellSize)) / 2);
  function deal() {
    // wordArr = words_filter.splice(gsap.utils.random(0, words_filter.length - 1, 1), 1).toString().toUpperCase().split('') // pull a random word, convert to upper case, then separate chars into an array
    // wordArr = allQuestions[currentQuestionIndex].question.toString().toUpperCase().split('') // pull a random word, convert to upper case, then separate chars into an array
    wordArr = splitWordPreservingModifiers(allQuestions[currentQuestionIndex.current].question)
    let a = wordArr;
    leftSide = ((divWidth - ((cellDistance * (a.length - 1)) + cellSize)) / 2);
    console.log(wordArr)
    let sameArr = true;
    while (sameArr) {
      // wordArr = a.toUpperCase().split('')
      mixedArr = gsap.utils.shuffle(gsap.utils.shuffle(gsap.utils.shuffle([...wordArr]))); // create a second array and triple shuffle its contents
      if (wordArr.join('') != mixedArr.join('')) { sameArr = false; }
    }
    noOfLetter = a.length
    let j = noOfLetter;

    console.log(noOfLetter)
    let slotsHtml = ``;
    let tilesHtml = ``;
    while (j) {
      slotsHtml = `<div class="slot" id="s${j}"></div> ` + slotsHtml;
      tilesHtml = `<div class="tile" id="t${j}">
        <div class="tileShade"></div>
        <div class="tileFront"></div>
      </div> `+ tilesHtml;
      j--;
    }
    let slotAndTiles = slotsHtml + tilesHtml;
    document.querySelector('.slotAndTiles').innerHTML = slotAndTiles;

    gsap.timeline()
      .set('.slot', { width: cellSize, height: 110, borderRadius: 10, background: successBackground, top: 50, left: leftSide, x: (i) => i * cellDistance })
      .set('.tile', { width: cellSize - 4, height: 106, top: 52, left: leftSide + 2, x: (i) => i * cellDistance, color: letterColor, fontSize: 30, lineHeight: '80px', textAlign: 'center' })
      .set('.tileFront', { width: cellSize - 8, height: 102, pointerEvents: 'none', borderRadius: 8, overflow: 'hidden', backgroundSize: 'cover', backgroundImage: tileFrontImage })
      .set('.tileShade', { width: cellSize - 8, height: 102, pointerEvents: 'none', backgroundSize: 'cover', backgroundImage: tileShadeImage, y: 2, scale: 1.1, opacity: 0.8 })

    Draggable.create('.tile', { onPress: (t) => { pickup(t) }, onDrag: drag, onRelease: drop });



    gsap.timeline()
      .set('.tileFront', { innerHTML: (i) => mixedArr[i], userSelect: 'none' })
      .fromTo('.tile', { x: (i) => i * cellDistance, opacity: 0 }, { duration: 0.1, opacity: 1, stagger: 0.06, ease: 'power1.inOut' }, 0)
      .fromTo('.tile', { y: 50, rotation: 0.05 }, { duration: 0.3, y: 0, rotation: 0, stagger: 0.06, ease: 'power4.out' }, 0)
      .to('.slot', { duration: 0.1, background: 'rgba(0,0,0,0.5)' }, 0)
      .call(function () {
        for (var i = 1; i <= noOfLetter; i++) Draggable.get('#t' + i).enable(); //enable tile dragging (disabled on correct answer + time-out)
        checkAnswer(); //check to see if any tiles are already in the correct slot
      }, null, 0.4);



    if (timeInt == undefined) { //start timer
      timeLeft = timeLimit;
      timeInt = setInterval(updateTime, 10);

      // clear correct answers (needed for replay)
      // $('.correctN').html(0);
      // $('.correctList').html('');
    }
  }


  function pickup(t) { // on press, set vars and animate tile lifting up
    currentTile = t.target;
    emptySlot = gsap.getProperty(currentTile, 'x');
    gsap.timeline({ defaults: { duration: 0.2, overwrite: true, ease: 'power3.out' } })
      .to($(currentTile).children('.tileFront')[0], { scale: 1.1 }, 0)
      .to($(currentTile).children('.tileShade')[0], { y: 30, opacity: 0.4, scale: 1 }, 0)
  }


  function drag() {
    for (var i = 1; i <= noOfLetter; i++) { // loop through all 6 tiles...
      if (currentTile.id.substr(-1) != String(i)) { // and only hit-test against the other tiles
        var t = '#t' + i;
        if (!gsap.isTweening(t)) { // also, only hit-test when tile is stationary
          if (Draggable.hitTest(currentTile, t, 40)) { // if at least 30px are overlapping...
            gsap.timeline()
              .to(t, { duration: 0.1, y: () => gsap.getProperty(currentTile, 'y') < 0 ? 66 : -66, ease: 'slow(0.7,0.7)', repeat: 1, yoyo: true, zIndex: 100 }, 0)
              .to(t, { duration: 0.2, x: emptySlot, ease: 'power2.inOut' }, 0); // move tile to empty slot,

            emptySlot = gsap.getProperty(t, 'x'); // then update emptySlot
          }
        }
      }
    }
  }


  function drop() { // on release, put current tile in the empty slot
    gsap.timeline({ defaults: { duration: 0.1, overwrite: true, ease: 'power1.in' }, onComplete: () => { checkAnswer() } })
      .to(currentTile, { x: emptySlot, y: 0 }, 0)
      .to($(currentTile).children('.tileFront')[0], { scale: 1 }, 0)
      .to($(currentTile).children('.tileShade')[0], { y: 2, opacity: 0.8, scale: 1.1 }, 0)
  }


  function checkAnswer() {
    var arr = new Array(600);
    for (var i = 1; i <= noOfLetter; i++) {
      arr.splice(gsap.getProperty('#t' + i, 'x'), 1, $('#t' + i).children('.tileFront').html());
    }

    // Convert arr to proper array of characters by removing empty elements
    let currentArr = arr.filter(x => x !== undefined);

    // Compare each character unit
    for (var j = 0; j < noOfLetter; j++) {
      if (wordArr[j] === currentArr[j]) {
        gsap.to('#s' + (j + 1), { duration: 0.33, ease: 'bounce.out', background: successBackground });
      } else {
        gsap.to('#s' + (j + 1), { duration: 0.3, background: 'rgba(0,0,0,0.5)' });
      }
    }

    // Compare full words
    if (wordArr.join('') === currentArr.join('')) {
      currentTile = emptySlot = undefined
      for (var i = 1; i <= noOfLetter; i++) Draggable.get('#t' + i).disable(); // disable all tiles
      insertData(1, playerData.msisdn, playerRound + 1, questionsIdLocal++, 'notSelected', 'general', eventDetails.id)
      var n = Number($('.correctN').html());
      // setFinalScore(n);
      console.log(totalRightAnswer)
      let result = n + 1;
      console.log(result)
      setTotalRightAnswer(result)
      $('.correctN').html(n += 1); // add to correct number tally
      // $('.correctList').append(arr.join('') + '<br>'); // add to list of correct words

      timeLeft += timePlusSec; // 1-sec bonus
      currentQuestionIndex.current++;
      gsap.timeline({
        onComplete: () => {
          if (currentQuestionIndex.current < allQuestions.length) {
            deal();
          } else {
            setShowResultModal(true);
            gameDone();
          }
        }
      }) // success animation, then deal() new letter tiles
        // .to('.timePlus', { duration: 0.1, opacity: 1, yoyo: true, repeat: 1, repeatDelay: 0.4 }, 0)
        // .fromTo('.timePlus', { scale: 0, rotation: 0.1 }, { duration: 0.3, scale: 1, rotation: 0, ease: 'back.out(3)' }, 0)
        .to('.slot', { duration: 0.2, background: 'rgba(0,0,0,0.5)', overwrite: true }, 0)
        .to('.tile', { duration: 0.35, scale: 1.16, ease: 'back.inOut(7)' }, 0.1)
        .to('.tile', { duration: 0.2, opacity: 0, scale: 1, ease: 'power1.inOut' }, 0.8)
    }
  }
  function miliSecondsToMS(ms) {
    let dt = new Date(ms).toISOString().slice(14, 19)
    return dt;
  }

  function updateTime() {
    if (timeLeft > 0) {
      timeLeft -= 10;
      var mil = Math.floor(timeLeft % 1000 / 10);
      var sec = Math.floor(timeLeft / 1000);
      if (mil < 10) mil = "0" + mil;
      if (sec < 10) sec = "0" + sec;
      var t = sec;
      gsap.set('.timeTxt', { innerHTML: miliSecondsToMS(timeLeft) });
      if (!document.querySelector('.timeTxt')) {
        clearInterval(timeInt);
      }
    }

    else { // Game over
      clearInterval(timeInt);
      setShowResultModal(true);
      gameDone();
      timeInt = currentTile = emptySlot = undefined;
      var n = Number($('.correctN').html());
      let formHtml = `      <h2 style="text-align: center;" class="lastResult">Your Score: ${n} </h2 style="text-align: center;">
        </div>`

      if (Draggable.get('#t1')) {
        for (var i = 1; i <= noOfLetter; i++) {
          var draggableInstance = Draggable.get('#t' + i);
          if (draggableInstance) {
            draggableInstance.disable();
          } else {
            console.warn('No draggable instance found for #t' + i);
          }
        }
      } else {
        console.error('Draggable instance for #t1 not found.');
      }

      gsap.timeline({ defaults: { duration: 0.001, overwrite: true } })
        // auto-solve the last word
        .to('.slot', { duration: 0.2, ease: 'power1.inOut', background: successBackground }, 0)
        .to('.tile', { scale: 1, y: 0, x: (i) => i * cellDistance }, 0.1)
        .to('.tileFront', { scale: 1, innerHTML: (i) => wordArr[i], userSelect: 'none' }, 0.1)
        .to('.tileShade', { y: 2, opacity: 0.8, scale: 1.1 }, 0.1)
        // hide timer + display replay button
        .to('.timeTxt', { duration: 0.3, opacity: 0 }, 0)
      // .to('.playTxt', { duration: 0.3, innerHTML: 'Replay', userSelect: 'none' }, 0)
      // .to('.playBtn', { duration: 0.3, autoAlpha: 1, ease: 'power1.inOut' }, 0.3)
      // .to('.app', { innerHTML: formHtml }, '>3')
    }
  }
  useEffect(() => {
    cellSize = 50;
    cellDistance = cellSize + 6;
    divWidth = $('.slotAndTiles').width();
    leftSide = ((divWidth - ((cellDistance * 4) + cellSize)) / 2);

    deal();
    insertData(0, playerData.msisdn, playerRound + 1, 2106, 'notSelected', 'general', eventDetails.id);
  }, [])
  function skipQuestion() {
    console.log('currentQuestionIndex in skipQuestion:', currentQuestionIndex.current)
    insertData(0, playerData.msisdn, playerRound + 1, questionsIdLocal++, 'notSelected', 'general', eventDetails.id)
    currentQuestionIndex.current++;
    if (currentQuestionIndex.current < allQuestions.length) {
      deal();
    } else {
      setShowResultModal(true);
      gameDone();
    }
  }
  return (
    <>
      <div className="quiz_navbar">
        <div className="backBtn" alt="" srcSet="" onClick={() => window.location.href = "../"} ><IoArrowUndoOutline /></div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '10%', position: 'relative' }}>
        <div className="timeBoxMIx" style={{
          width: "20%", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: '#fff',
          fontWeight: 600
        }}>
          {/* <div>Timer</div> */}
          <div className="timeTxt" style={{ display: 'none' }}>00:00</div>
          {/* <div className="timeTxt1">{remainingTime}</div> */}

          <div class="single-chart">
            <svg viewBox="0 0 36 36" class="circular-chart orange">
              <path class="circle-bg"
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path class="circle"
                stroke-dasharray={`${timerWidth}, 100`}
                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="18" y="20.35" class="percentage">{secondsToMS(parseFloat((remainingTime < 0) ? '0' : remainingTime))}</text>
            </svg>
          </div>

        </div>

        <div style={{
          width: "50px", display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: '#fff',
          fontWeight: 600, border: '1px solid #fff', borderRadius: '5px'
        }}>
          <div className="correctN" style={{ fontSize: '20px' }}>0</div>
          <div className="correctHead">Score</div>
        </div>
      </div>
      {/* <div style={{ height: '4dvh', width: '95%', position: 'relative', maxHeight: '24px', margin: '5% auto' }}>
        <TimerBoxLinear timerWidth={timerWidth} outerBorderColor="#fff" innerColor="#257dff"></TimerBoxLinear>
      </div> */}
      <div className="background"></div>
      <div className="slotAndTiles">
        <div className="slot" id="s1"></div>
        <div className="slot" id="s2"></div>
        <div className="slot" id="s3"></div>
        <div className="slot" id="s4"></div>
        <div className="slot" id="s5"></div>


        <div className="tile" id="t1">
          <div className="tileShade"></div>
          <div className="tileFront">H</div>
        </div>
        <div className="tile" id="t2">
          <div className="tileShade"></div>
          <div className="tileFront">O</div>
        </div>
        <div className="tile" id="t3">
          <div className="tileShade"></div>
          <div className="tileFront">U</div>
        </div>
        <div className="tile" id="t4">
          <div className="tileShade"></div>
          <div className="tileFront">S</div>
        </div>
        <div className="tile" id="t5">
          <div className="tileShade"></div>
          <div className="tileFront">E</div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15%' }}>
        <button onClick={skipQuestion} style={{ backgroundColor: '#fff', padding: '6px 40px', borderRadius: '6px', fontWeight: 600 }}>Skip</button>
      </div>
    </>
  )
}

export default MixUp