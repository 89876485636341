import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { baseUrl, dataContext, portal } from "../../App";
import activityLogSubmission from "../../helper/activitylog";
import analyticEvent from "../../helper/gaEvent";
import "./Leaderboard.css";
import { HallofFame, RankTable, UserRow } from "./components";
import { LeaderBoardSelection } from "./LeaderboardSelection";
import cupImageBlend from './cupBlend.png'

const Leaderboard = ({ activeBoard, setActiveBoard, toggleToUpdate, setToggleToUpdate }) => {
  const { playerData, events } = useContext(dataContext);
  const [leaderBoardData, setLeaderBoardData] = useState([]);

  const [rankOneData, setRankOneData] = useState({});
  const [rankTwoData, setRankTwoData] = useState({});
  const [rankThreeData, setRankThreeData] = useState({});


  useEffect(() => {
    setToggleToUpdate(!toggleToUpdate)
  }, [])

  useEffect(() => {
    if (events.length) {
      if (!activeBoard) setActiveBoard(events[0]?.id);
      axios
        .get(`${baseUrl}/api/leaderboard/?portal=${portal}&event_id=${activeBoard ? activeBoard : events[0]?.id}`)
        .then((response) => {
          let totalData = response.data;
          if (totalData.length > 0) { setRankOneData(totalData[0]); } else { setRankOneData({}); }
          if (totalData.length > 1) { setRankTwoData(totalData[1]); } else { setRankTwoData({}); }
          if (totalData.length > 2) { setRankThreeData(totalData[2]); } else { setRankThreeData({}); }
          if (totalData.length > 3) { setLeaderBoardData(totalData.slice(3)); } else { setLeaderBoardData([]); }
        });
    }
  }, [activeBoard]);

  useEffect(() => {
    let msisdn = null;
    if (playerData?.id) { msisdn = playerData?.msisdn; }
    activityLogSubmission("leaderboard", msisdn);
    analyticEvent('leaderboard', 'leaderboard-view')
  }, [])


  return (
    <>
      <LeaderBoardSelection events={events} activeBoard={activeBoard} setActiveBoard={setActiveBoard} />
      <div className="leaderBoardWrapper">
        <HallofFame rankOneData={rankOneData} rankTwoData={rankTwoData} rankThreeData={rankThreeData} />
        <div className="rankTableWrapperBg">
          <RankTable leaderBoardData={leaderBoardData} />
          {playerData?.id && <UserRow playerData={playerData} activeBoard={activeBoard} />}
          <img src={cupImageBlend} alt="" className="cupImageBlend" />
        </div>
        {/* end wrapper */}
      </div>
    </>
  );
};

export default Leaderboard;
