import React, { useContext, useState } from 'react'
import '../../../assets/css/modal.css'
import './ResultModal.css'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaMedal  } from "react-icons/fa";
const ResultModal = ({ showResultModal, setShowResultModal, finalScore }) => {
  const navigate = useNavigate()
  function hideModalOperation() {
    setShowResultModal(false)
    navigate(`../`, { replace: true })
  }
  return (
    <>
      <Modal show={showResultModal} centered dialogClassName="modal-dialog" contentClassName="modalContent" onHide={hideModalOperation}>
        <Modal.Header closeButton >
          <Modal.Title>Score</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center fs-5">Your Score</div>
          <div className="finalScore text-center fs-4">{finalScore}</div>
          <div className="allBtn d-flex justify-content-center w-90 gap-4 mt-3">
            <div className="btn btn-light modalButtonIcon" onClick={() => { navigate(`../?page=home`, { replace: true }); }}><FaHome /></div>
            <div className="btn btn-light modalButtonIcon" onClick={() => { navigate(`../?page=leader`, { replace: true }); }}><FaMedal /></div>
          </div>
        </Modal.Body>

      </Modal>
    </>
  )
}

export default ResultModal